// import {Platform} from "react-native"
// const PlatformWeb = Platform.OS === "web"
// const chatBaseurl = "104.219.41.18:8081/aauti/"
const chatBaseurl = "wss://aautimpapi.azurewebsites.net/aautiMeet?userId:";
const chatQaUrl = "wss://aautimpqa.azurewebsites.net/aautiMeet?userId:";
const chatDemoUrl = "wss://aautidemo.azurewebsites.net/aautiMeet?userId:";
const chatProdUrl = "wss://aautiprodapi.aauti.com/aautiMeet?userId:";

//baseurls
const azureDevUrl = "https://aautimpapi.azurewebsites.net/";
const azureQaUrl = "https://aautimpqa.azurewebsites.net/";
const azureDemoUrl = "https://aautidemo.azurewebsites.net/";
const productionUrl = "https://aautiprodapi.aauti.com/";

//file upload urls
export const DevUploadPlatform = "marketPlace/dev";
export const QaUploadPlatform = "marketPlace/qa";
export const DemoUploadPlatform = "marketPlace/demo";
export const productionUploadPlatform = "marketPlace/prod";
export const uploadUrl = "https://upload.aauti.com/";

export const IPStickAccessKey = "7c2453ecf875d030ae32a311f106f207";

//app_tokens
export const appTokenDev =
  "app_5B7768DA83B0AE019F437C0CCD61C5EE52A9ED60F3A566EBE1F286009B61";
export const appTokenQa =
  "app_9FC1E1BEE54DAB88209E75B7A647D9D1EAED0D2940EF59E056B383A09CA8";
export const appTokenDemo =
  "app_292DF0E8972B981E514B6799EAB86F9615661D3859122F79F25CDCB69BFF";
export const appTokenProduction =
  "app_48891EEC13DABBE3F48C451C3357C252FEC8EF266947863D5BBA86174DD4";

export const baseUrl = azureDemoUrl;
export const appToken = appTokenDemo;
export const chatUrl = chatDemoUrl;
export const UploadBaseUrl = DemoUploadPlatform;

export const ApiPaths = {
  BASE_URL: baseUrl,
  CHAT_WS_BASE_URL: chatUrl,
  IMAGE_URL: "http://104.219.41.18:8081/aautimarketplace/",
  GET_SERVICE_PROVIDER_DETAILS: "myCourse/getCompleteCourseDetails?courseId=",
  GET_DRAFT_GIGS_BY_SERVICEPROVIDER_ID:
    "draftGig/getDraftGigByServiceProviderId",
  GET_MY_COURSES_BY_USERID: "myCourse/getCoureseBySpIdAndType?",
  UPLOADCONTENT_BASEURL: "content/saveContent",
  GET_CONTENT_BY_CATEGORY_AND_SUBCATEGORY:
    "content/getContentByCategoryAndSubCategory",
  GETCONTENTBYID: "content/getContent?id=",
  UPDATECONTENTBYID: "content/updateContent?id=",
  GET_SESSIONS_BY_COURSEID_BATCHID_ROLE:
    "session/getSessionByCourseIdAndBranchId",
  UPDATE_CONTENT: "content/updateContent",
  GET_CONTENT_BY_CATEGORY_AND_SUBCATEGORY_AND_LEVEL_BY_USERID:
    "content/getContentByCategoryAndSubCategoryAndLevelandUserId?",
  GET_SESSION_MAP_BY_START_END_DATES:
    "session/getSessionMapByStartDateAndEndDate",
  GETALLCONTENTS: "content/getAllContents",
  DELETECONTENTBYID: "content/deleteContent?contentId=",
  GET_ALL_PACKAGES: "/package/getAllPackages",
  GET_PACKAGES_BY_SERVICEPROVIDER_ID: "package/getPackagesByServiceProviderId",
  GET_UPCOMING_COURSES_BY_USER_ID: "myCourse/getAllCoursesBySpId",

  /** Auth Services */
  UPCOMING_EVENTS: "AautiEvent/upcomingEvents",
  LOGIN: "user/login?",
  /* file upload */
  FILE_UPLOAD: "file/Upload?aws=true",
  FILE_UPLOAD_MULTI: "file/multiUpload?aws=true",
  /** changepassword Services */
  CHANGE_PASSWORD: "user/changePassword?",
  /** forgotpassword Services */ FORGOT_PASSWORD: "app/forgotPassword?email=",
  UPDATE_PASSWORD: "user/changePassword",
  VERIFY_OTP: "app/verifyOtp",
  GET_GENARATEINVOICE_BY_ORDER_ID: "orderDetails/getinvoiceOrderDetails",
  SAVE_PACKAGE: "package/savePackage",
  GET_GENARATEINVOICE_BY_ORDER_ID_PDF: "orderDetails/getInvoiceAsPDF",
  /** getServiceProvidersBySubCategory Services */
  GET_SERVICEPROVIDERS_BY_SUBCATEGORY:
    "course/getServiceProvidersBySubCategory?",

  /** Get socialLogin userData Services */
  SOCIALLOGIN: "user/getUsers?",

  /** Get appleAuth userData Services */
  APPLE_AUTH: "user/appleLogin?",

  /**Get all ratingreviews Services */
  GETALL_RATING_REVIEWS: "ratingsReviews/getRatingsReviewsByServiceProviderId",

  /**saveRatingsReviews Services */
  SAVE_RATINGS_REVIEWS: "ratingsReviews/saveRatingsReviews",

  /** REGISTRATION Auth Services */
  REGISTER: "user/saveUser",

  /** getSocial Services */
  SOCIAL_LOGIN: "user/socialLogin",

  /** getAllCategories Services */
  GET_ALL_CATEGORIES: "category/getAllCategories",

  /** getAllCartsByUser Services */
  GET_ALL_CARTS_BY_USER: "cart/getAllCartsByUser?endUserId=",

  /** getAllNotifications Services */

  /** getAllNotifications by user Services */
  GET_ALL_NOTIFICATIONS_USER: "notification/getAllNotificationsByUser",
  DELETE_NOTIFICATION_BY_ID: "notification/deleteNotification?",
  DELETE_MULTIPLE_NOTIFICATIONS: "notification/deleteMultipleNotifications",
  DELETE_ALL_NOTIFICATIONS_BY_USEID:
    "notification/deleteAllNotificationsByUser",
  CLEAR_NOTIFICATION: "notification/clearNotification",
  CLEAR_ALL_NOTIFICATIONS: "notification/clearAllNotificationsByUser?userId=",
  AUTH_CHECK_YOUR_HAS_DATA: "user/userByMobileNumber?phoneNo=",
  GET_SERVICEPROVIDER_REVENUE_BY_SERVICEPROVIDER_ID:
    "attendance/getServiceProviderRevnue?serviceProviderId=",
  GET_FILTERS: "filters/getAllFilters",

  /** clearAllNotificationsByUser Services
   * Method:PUT */
  PUT_CLEAR_ALL_NOTIFICATIONS_BY_USER:
    "notification/clearAllNotificationsByUser?userId=",

  /** SaveOrderDetails Services */
  POST_SAVE_ORDER_DETAILS: "orderDetails/saveOrderDetails",

  /** updateOrderDetails Services */
  PUT_UPDATE_ORDER_DETAILS: "orderDetails/updateOrderDetails",

  /** getOrderDetailsByUserId Services */
  GET_ORDER_DETAILS_BY_USERID: "orderDetails/getOrderDetailsByUserId?userId=",

  // grades
  GET_ALL_GRADES: "grades/getAllGrades",

  /** Save Cart Services */
  POST_UPDATE_CART: "cart/saveCart",
  SAVE_ATTENDANCE: "attendance/saveAttendance",

  /** update Cart Services */
  PUT_UPDATE_CART: "cart/updateCart",

  /** getRecentSchedulersByDate Services */
  GET_RECENT_SCHEDULERS_BY_DATE:
    "scheduler/getRecentSchedulersByDate?currentDate=",

  /** getRecentSchedulersBy selected Date Services recent 24hr*/
  GET_RECENT_SCHEDULERS_BY_SELECTED_DATE:
    "scheduler/getSchedulersByDate?selectedDate=",

  /** subscribe page getScheduler based on id  Services */
  GET_RECENT_SCHEDULERS_BY_ID: "scheduler/getScheduler?id=",

  /** search page getSchedulers based on CourseId  Services */
  GET_SCHEDULERS_BY_COURSE_ID: "scheduler/getSchedulersByCourseId?courseId=",

  /** subscribe page getScheduler based on user id  Services */
  GET_RECENT_SCHEDULERS_BY_USER_ID:
    "scheduler/getSchedulesByUserIdDate?userId=",

  /** getallScheduler based on user id  Services */
  GET_ALL_SCHEDULERS_BY_USER_ID: "scheduler/getSchedulesByUserId?userId=",

  /** getTopServiceProviders Services */
  GET_TOPSERVICEPROVIDERS:
    "ratingsReviews/getTopServiceProvidersRatingsReviews?endUserId=",
  //before login
  GET_TOPSERVICEPROVIDERS_BEFORE_LOGIN:
    "ratingsReviews/getTopServiceProvidersRatingsReviews",
  /** getNewServiceProviders Services */
  GET_NEWSERVICEPROVIDERS: "user/getNewServiceProviders",
  DELETE_LESSONPLAN: "myLessonPlan/deleteLessonPlan?id=",

  GET_USERBY_MAIL: "user/getUserByEmail?email=",
  GET_ALL_SUBSCRIPTIONS_BY_USER_ID: "subscription/getAllSubscriptionsByEndUser",

  /** getFreeliveClasses  Services */
  GET_FREE_LIVE_CLASSES: "scheduler/getFreeliveClasses?currentDate=",

  /** get portfolio based on service provider id */
  GET_PORT_FOLIO:
    "portfolio/getPortfoliosByServiceProviderId?serviceProviderId=",

  /** deletePortfolio based on  portfolioId
   */
  DELETE_PORTFOLIO: "portfolio/deletePortfolio?portfolioId=",

  /** savePortfolio
   */
  CREATE_NEW_PORTFOLIO: "portfolio/savePortfolio",

  /** updatePortfolio
   */
  UPDATE_PORTFOLIO: "portfolio/updatePortfolio",

  /** get portfolio based on service provider id */
  GET_ALL_PORT_FOLIO: "portfolio/getAllPortfolios",

  /** get user data based on search query string */
  GET_USER_SEARCH_DATA: "user/search?searchKey=",

  /** Save wishlist Service */
  POST_SAVE_WISHLIST: "wishlist/saveWishlist",

  /** update Wishlist Service */
  POST_UPDATE_WISHLIST: "wishlist/updateWishlist",

  /** deleteWishlist Service */
  DELETE_WISHLIST: "wishlist/deleteWishlist",

  /** Get wishlist data based on userId Service */
  GET_WISHLIST_DATA_BY_USERID: "wishlist/getWishlistByUserId",

  GET_LESSON_PLAN: "scheduler/getScheduler?",

  /**Update User Details */
  UPADTE_USER_DETAILS: "user/updateUser",

  /**Get userdetailsby id */
  GETUSERSBYID: "user/getUserById?",

  /**matchMySchedule*/
  MATCH_MY_SCHEDULE: "scheduler/matchMySchedule?",

  /**Post FCM Token details by email */
  POST_FCM_TOKEN_DETAILS: "userDevices/saveUserDevice",

  /** set user logged in status for chat user list  */
  POST_USER_LOGGED_IN: "user/userLoggedIn",

  /** Service Provider Service */
  /** subscription */

  /* timeslots API */
  GET_TIME_SLOTS_BY_USER_ID: "mySchedule/getMySchedulesByUserId?userId=",
  SAVE_NEW_SCHEDULE: "mySchedule/saveMySchedule",
  UPDATE_TIME_SLOT: "mySchedule/updateMySchedule",

  /* Event Enroll API */
  GET_EVENT_BY_ID: "AautiEvent/getEventById?id=",
  GET_EVENT_ORDER_DETAILS: "eventOrderDetails/getEventOrderDetails?id=",
  UPDATE_EVENT_ORDER_DETAILS: "eventOrderDetails/updateEventOrderDetails",
  SAVE_EVENT_ORDER_DETAILS: "eventOrderDetails/saveEventOrderDetails",
  SAVE_EVENT_ENROLL_FORM: "EnrollEvent/saveEvent",
  COURSE_MATCH_MY_SCHEDULE: "myCourse/matchMySchedule",

  // leeson-plan-controller
  SAVE_LESSONPLAN: "/myLessonPlan/saveLessonPlan",
  GET_LESSONPLAN: "myLessonPlan/getLessonPlanByUserId?userId=",
  UPDATE_LESSONPLAN: "myLessonPlan/updateLessonPlan",
  //country
  GET_ALLCOUNTRIES: "countryMapping/getAll",
  GETSTATES_BYCOUNTRY: "stateMapping/getListOfStatesByCountryId?countryId=",
  GET_ALL_STATES: "stateMapping/getAllStates",
  SAVEUSER: "user/saveUser",
  GET_COURSES_NEXT_EIGHT_HOURS: "myCourse/getCoursesNextEightHours",
  GET_COURSES_NEXT_EIGHT_HOURS_FORENDUSER:
    "myCourse/getCoursesNextEightHours?endUserId=",
  SEND_MAIL: "app/sendMail?email=",
  GETUSERSBYIDNEW: "user/getUserById?",
  GET_RELATED_GIGS: "gig/relatedGigs",
  // find courses
  GET_FIND_COURSES: "myCourse/getCoursesCountByCategoryAndSubcategory",
  GET_COURSES_BY_CATEGORY: "myCourse/getCoursesListByCategory?",
  GET_COURSES_BY_SUBCATEGORY:
    "myCourse/getCoursesBySelectedSubCategories?subCategory=",
  GET_DRAFT_COURSE_BY_SERVICEPROVIDER_ID:
    "draftCourse/getDraftCourseByServiceProviderId",
  DELETE_DRAFT_COURSE: "draftCourse/deleteDraftCourse",
  /* Save course API */
  SAVE_COURSE_DETAILS: "myCourse/saveCourse",
  UPDATE_COURSE_DETAILS: "myCourse/updateCourse",
  DRAFT_COURSE_DETAILS: "draftCourse/saveDraftCourse",
  UPDATE_DRAFT_DETAILS: "draftCourse/updateDraftCourse",
  GETALL_LANGUAGES: "languages/getAllLanguages",
  GETUSERBYEMAIL: "user/getUserByEmail?email=",
  GET_GIGS: "gig/getAllGigs?endUserId=",
  // curl -X GET "http://20.150.219.28:8080/aauti_qa/gig/getGig?endUserId=610cef894ff3935a6575dd79&id=6311cdc28f4bbe09121c2985" -H "accept: */*"
  GET_GIGBY_USER_ID: "gig/getGig?endUserId=",
  GET_GIGBY_ID: "gig/getGig?id=",
  GIG_DETAILS: "gig/getCompleteGigDetails?",
  UPDATE_GIG: "gig/updateGig",
  GET_SESSION_BY_DATE: "session/getSessionByDate",
  GET_MY_SESSION_BY_DATE: "session/getMySessionByDate",
  GET_SERVICE_PROVIDER_SESSIONS_BY_DAYE:
    "session/getServiceProviderSessionByDate",
  GET_REPORTS: "reportType/getAllReportTypes",
  GET_ALL_WISHLISTS: "wishlist/getWishlistByUserId?endUserId=",
  DELETE_WISHLIST_ITEM: "wishlist/deleteWishlistById",
  ADD_ITEM_TO_CART_FROM_WISHLIST: "cart/saveCart",
  GET_LESSON_PLAN_BY_USERID: "/myLessonPlan/getLessonPlanByServiceProviderID",
  GET_FREE_LIVE_CLASES: "myCourse/getFreeDemoCourses",
  DELETE_ADDED_TO_CART_WISHLIST: "/wishlist/deleteWishlist",
  GET_ALL_RECENT_GIGS: "gig/getAllRecentGigs",
  GET_ALL_RECENT_GIGS_LOGGEDOUT: "gig/getAllRecentGigs?noOfRecords=",
  SAVE_GIG: "gig/saveGig",
  GET_MYGIGSBYUSERID_ENDUSER: "gig/getMyGigsByUserId?endUserId=",
  GET_MYGIGBYUSERID_SERVICEPROVIDER: "gig/getGigListByServiceProviderId",
  GET_SERVICEPROVIDER_DASHBOARD_DETAILS:
    "myCourse/getServiceProviderDashboardDetails?serviceProviderId=",
  GET_ENDUSER_DASHBOARD_DETAILS_COURSES: "myCourse/getCoursesByUserId?userId=",

  GET_SERVICE_PROVIDER_START_END_DATES_SESSIONS:
    "session/getServiceProviderSessionByStartDateAndEndDate?",
  GET_END_USER_START_END_DATES_SESSIONS:
    "session/getEndUserSessionByStartDateAndEndDate?",

  GET_COURSE_DETAILS_BY_ID: "myCourse/getCompleteCourseDetails?courseId=",
  GET_CLASS_DETAILS_BY_ID: "/myCourse/getClassDetailsByCourseId?",
  GET_RATING_REVIEWS_BY_SERVICEPROVIDER_ID:
    "ratingsReviews/getRatingsReviewsByServiceProviderId",
  SEARCH_GIGS: "gig/searchGigs?searchKey=",
  GET_COURSES_BY_SUBCATEGORYFOR_EndUserID:
    "myCourse/getcourseslistBysubCategory?endUserId=",
  GETSUBCATEGORY_BYCATEGORY: "category/getCategory?id=",
  DELETECART: "cart/deleteCart",
  GET_LESSON_PLAN_BY_LESSONPLAN_ID: "myLessonPlan/getLessonPlan?id=",

  GETORDERDETAILS: "orderDetails/getRecentOrderDetails?noOfDays=",
  GETSERVICEPROVIDER_ORDERDETAILS:
    "orderDetails/getRecentOrderDetailsByServiceProviderId?noOfDays=",
  GET_LESSON_PLAN_TOPIC_BY_LESSONPLAN_ID:
    "myLessonPlan/getLessonPlanTopicById?id=",
  GET_SERVICEPROVIDER_SESSION_BY_STARTDATE:
    "session/getServiceProviderSessionByDate",
  GET_UPCOMING_COURSES: "myCourse/getUpcomingCourses",
  GET_GIGREVIWES_BYID: "ratingsReviews/getRatingsReviewsByGigId?gigId=",
  DELETE_CART_BY_CART_ID: "cart/deleteCartById",
  DELETE_GIG_BY_ID: "gig/deleteGigById?",
  POST_SAVE_ADHOC_SCHEDULE: "mySchedule/saveAdhocSchedule",
  // GET_ADDRESS_BY_LAT_LONG: "addressByLangitudeAndLattitude",
  DELETE_COURSE: "myCourse/deleteCourse?id=",
  GET_ATTENDANCE: "attendance/getServiceProviderAttendanceForSession",

  GET_ENDUSER_ATTENDANCE: "attendance/getEndUserAttendanceForSession",
  GET_BLOCK_BUSTER_DEALS: "myCourse/getBlockbusterDealCourses",
  GET_RECENT_ORDER_DETAILS: "/orderDetails/getRecentOrderDetails",
  GET_RECENT_ORDER_DETAILS_BYSERVICE_PROVIDER_ID:
    "/orderDetails/getRecentOrderDetailsByServiceProviderId",
  GET_ADDRESS_BY_LAT_LONG: "app/addressByLangitudeAndLattitude",
  SEND_SMS: "sendSms?toPhoneNumber=",

  VERIFY_SMS: "verifySms?otp=",
  GET_USERBY_MOBILE: "/user/userByMobileNumber?phoneNo=",
  PROFILE_PERCENTAGE: "user/getProfilePercentage?role=endUser&userId=",
  GET_BY_COUNTRY_NAME: "countryMapping/getByCountryName?CountryName=",
  GET_REVIEWS_BY_COURSE_ID:
    "ratingsReviews/getRatingsReviewsByCourseId?courseId=",
  GET_ALL_COURSES: "myCourse/getAllCourses",
  GET_COURSE_BY_COURSE_ID: "myCourse/getCourse",

  //   /* Get ServiceProviders wishlist on endUserId /
  GET_WISHLIST_SERVICEPROVIDERS_DATA_BY_ENDUSERID:
    "wishlist/getWishlistedServiceProviders",

  //   /* Save ServiceProviders wishlist on endUserId /
  POST_WISHLIST_WISHLIST_SERVICEPROVIDER: "wishlist/wishlistServiceProvider",

  //   /* Delete ServiceProviders wishlist on endUserId /
  POST_WISHLIST_DELETE_WISHLIST_SERVICEPROVIDER:
    "wishlist/deleteWishlistedServiceProvider",
  UPDATE_RATING_REVIEWS: "ratingsReviews/updateRatingsReviews ",
  GETCOMPLETEORDERDETAILS: "orderDetails/getCompleteOrderDetails",
  // / Get Courses by subCategoryList /
  GET_COURSES_BY_SUBCATEGORY_LIST:
    "myCourse/getCoursesBySelectedSubCategories?subCategory=",
  GET_SERVICEPROVIDER_BASEPRICE_COUNTRIES:
    "adhocPricing/getServiceProviderBasePriceAndCountries",
  GET_BASEPRICE_COUNTRIES: "adhocPricing/getBasePriceAndCountries?",
  SAVE_ADHOC_PRICING: "adhocPricing/saveAdhocPricing",
  DELETE_RATING_REVIEWS_BY_REVIEWSID: "ratingsReviews/deleteRatingsReviews",
  GET_SESSION_MAP_BY_START_END_DATES_BY_SERVICEPROVIDER:
    "session/getSessionMapByStartDateAndEndDateByType",
  // revenue
  GET_TOTAL_REVENUE_BY_SERVICEPROVIDER_ID:
    "subscription/getTotalRevenueByServiceProviderId?serviceProviderId=",

  // Coupons
  GET_COUPONS_By_SERVICEPRIVIDER_ID:
    "coupon/getCouponDetailsByServiceProviderId?serviceProviderId=",

  POST_COMPLAINT_SAVE: "complaint/save",
  GET_SERVICEPROVIDERS_STUDENTS_LIST:
    "attendance/getServiceproviderStudentsList?serviceProviderId=",
  TRENDING_COURSES: "myCourse/getTrendingCourses",
  GET_COUNT_BY_EACH_CATEGORY: "category/getAllCategories",
  // GET_COUPONS_By_SERVICEPRIVIDER_ID:
  //   "coupon/getCouponDetailsByServiceProviderId?serviceProviderId=",
  SAVE_COUPON: "coupon/saveCouponDetails",
  UPDATE_COUPON: "coupon/updateCouponDetails",
  DELETE_COUPON: "coupon/deleteCouponDetails?id=",
  REFUND_REQUEST: "paymentRefund/savePaymentRefund",
  GET_SESSIONBY_COURSEID_BATCHID: "session/getRecentSession?",

  // Poll
  SAVE_POLL_DRAFT: "draftPoll/saveDraftPollCreation",
  GET_ALL_POLL_DRAFTS: "draftPoll/getAllDraftPollCreations",
  GET_DRAFT_SP_POLL_DETAILS:
    "draftPoll/getDraftPollDetailsByBatchIdOrSessionIdOrServiceProviderId",
  GET_POLLDETAILS_BY_BATCHID_SESSIONID:
    "poll/getPollDetailsByBatchIdOrSessionId?",
  SAVE_POLL: "poll/savePoll",
  GET_SP_POLL_DETAILS: "poll/getServiceProviderIdPollDetails",
  GET_ENDUSER_POLL_DETAILS: "poll/getEndUserPollDetails",
  UPDATE_POLL: "poll/updatePoll",
  UPDATE_POLL_DRAFT: "draftPoll/updateDraftPollCreation",
  DELETE_POLL: "poll/deletePoll",
  DELETE_DRAFT_POLL: "draftPoll/deleteDraftPollCreation",
  SUBMIT_POLL: "poll/submitPoll",

  GET_GIG_REVIEWS_BY_GIGID: "ratingsReviews/getDetailedRatingsReviewsByGigid",
  // Join Class
  GET_CLASS_DETAILS: "myCourse/getClassDetailsByCourseId",

  GET_END_DASHBOARD_DETAILS: "myCourse/getEndUserDashboardDetails?",

  //feed recordings

  GET_RECORDINGS_BY_BATCHID: "file/getRecordingsByBatchId?batchId=",

  //jayasree

  GET_ALL_INSTITUTE_TYPES: "instituteTypes/getAllInstituteTypes",
  GET_ALL_ACADEMIC_SYSTEMS: "academicSystem/getAllAcademicSystems",
  GET_All_GRADES: "grades/getAllGrades",
  GET_INSTITUTE_COMPLETED_COURSES:
    "institution/getCompletedInstituteCourseDetails",
  GET_INSTITUTE_UPCOMING_COURSES:
    "institution/getUpcomingInstituteCourseDetails",
  GET_INSTITUTE_ONGOING_COURSES: "institution/getOngoingInstituteCourseDetails",
  GET_INSTITUTE_RECENT_GIGS: "gig/getAllRecentGigs",

  //payments
  paymentMethods: "payment/paymentMethods?",
  orderDetails: "orderDetails/getCompleteOrderDetails?",
  cardPayment: "payment/cardPayment",
  updateOrder: "orderDetails/updateOrderDetails",
  order_details_id: "orderDetails/getCompleteOrderDetails?orderId=",
  GET_PACKAGE_DETAILS_BY_ID: "package/getpackageDetailsById",

  GET_PACKAGES_BY_COURSE_IDS: "package/getPackagesByCourseIds?courseIds=",

  GET_ELIGIBILTY: "orderDetails/getEligibiltyToReportServiceProvider?",

  //location

  GET_USER_LOCATION: "app/addressByLangitudeAndLattitude?",
  GET_COURSE_MATERIAL_COURSEID_BATCHID:
    "courseMaterial/getCourseMaterialByCourseIdAndBatchId",
  SAVE_COURSE_MATERIAL: "courseMaterial/saveCourseMaterial",
  GET_MY_PROVIDERS: "/user/getMyServiceProviders",
  UPDATE_PACKAGE: "package/updatePackage",
  DELETE_PACKAGE: "package/deletePackage?id=",

  //Join Class #)#*#*#*##*

  // Task & Assignment
  GET_ASSIGNMENT_DETAILS_BY_BATCHID_SESSIONID:
    "assignmentCreation/getAssignmentDetailsByBatchIdOrSessionId",
  GET_ASSIGNMENTDETAILS_BY_SPID: "assignmentCreation/getUserAssignmentBySpId",
  SUBMIT_ASSIGNMENT_BY_EUID: "assignmentCreation/submitAssignment",
  // Ask
  GET_ASK_DETAILS_BATCHID_SESSIONID:
    "askCreation/getAskDetailsByBatchIdOrSessionId",
  SAVE_ASK: "askCreation/saveAskCreation",
  GET_ALL_DRAFTS_ASK_BY_SESSION_BATCH_SP:
    "draftAskCreation/getDraftAskDetailsByBatchIdOrSessionIdOrServiceProviderId",
  SAVE_ASK_DRAFT: "draftAskCreation/saveDraftAskCreation",
  UPDATE_DRAFT_ASK: "draftAskCreation/updateDraftAskCreation",
  UPDATE_ASK: "askCreation/updateAskCreation",
  DELETE_ASK_DRAFT: "askCreation/deleteAskCreation",
  DELETE_ASK: "askCreation/deleteAskCreation",

  // Agenda
  SAVE_AGENDA: "agenda/saveAgenda",
  SAVE_DRAFT_AGENDA: "draftAgenda/saveDraftAgendaCreation",
  UPDATE_AGENDA: "agenda/updateAgenda",
  UPDATE_DRAFT_AGENDA: "draftAgenda/updateDraftAgendaCreation",
  DELETE_AGENDA: "agenda/deleteAgenda",
  DELETE_DRAFT_AGENDA: "draftAgenda/deleteDraftAgendaCreation",
  GET_AGENDA_BY_BATCH_SESSION_ID: "agenda/getAgendaDetailsByBatchIdOrSessionId",
  GET_ALL_DRAFTS_AGENDA_BY_SESSION_BATCH_SP:
    "draftAgenda/getDraftAgendaCreationDetailsByBatchIdOrSessionIdOrServiceProviderId",
  UPDATE_ROOM_FREEZE: "chatRoom/updateRoomFreeze",
  REPORT_CHAT_ROOM: "complaint/reportInChatRoom",
  GET_ADHOC_CLASS_MATERIAL: "courseMaterial/getCourseMaterialBySessionId",

  //get ToDoList of SP
  GET_TODO_LIST: "myCourse/getToDoList",
};

export const ApiMethods = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT",
  PATCH: "PATCH",
};
